<template>
  <div>
    <van-tabbar route>
      <van-tabbar-item replace to="/" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item replace to="/query" icon="search">查询</van-tabbar-item>

      <!--<van-tabbar-item replace to="/ren" icon="upgrade">更新</van-tabbar-item>-->
      <!--<van-tabbar-item replace to="/ren" icon="passed">绑定</van-tabbar-item>-->
    </van-tabbar>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'tabbar',
  data() {
    return {
      active: 1,
    }
  },
}
</script>

<style scoped>

</style>