import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
    Button,
    Grid,
    GridItem,
    Tabbar,
    TabbarItem,
    Image as VanImage,
    Form,
    Field,
    Toast,
    Circle,
    Collapse,
    CollapseItem,
    Row,
    Col, Empty,Loading,Overlay
} from 'vant'

Vue.use(Button).use(Grid).use(GridItem).use(Tabbar).use(TabbarItem).use(VanImage).use(Form).use(Field).use(Toast).use(Circle).use(Collapse).use(CollapseItem).use(Row).use(Col).use(Empty).use(Loading ).use(Overlay )

const BASE_URL = 'process.env.VUE_APP_BASE_URL'

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
new Vue({
    router,
    store,
    BASE_URL,
    render: h => h(App)
}).$mount('#app')
