<template>
  <div id="app" :class="{'pc':!isMobile}">
    <Bar></Bar>
    <!--    <router-view/>-->
  </div>
</template>

<script>
import Bar from '@/components/tabbar'

export default {
  name: 'App',
  components: {
    Bar
  },
  data() {
    return {
      isMobile: false
    }
  },
  methods: {
    checkIsMobile() {
      setTimeout(() => {
        this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? true : false
        document.querySelector("body").style.background=this.isMobile?"#f8f8f8":`url(${require('@/assets/bg.jpg')})`
      })
    }
  },
  created() {
    this.checkIsMobile()
    window.onresize = () => {
      this.checkIsMobile()
    }
  }
}
</script>
<style lang="less">
//*{
//  background-color: black;
//}
.pc {
  width: 485px;
  position: fixed;
  height: 100%;
  min-height: 400px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: hidden;
  background: #f8f8f8;
}
</style>
