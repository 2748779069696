import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/index'),
        meta: {
            title: 'Xbk-Home'
        }
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/index'),
        meta: {
            title: 'Xbk-Home'
        }
    },
    {
        path: '/query',
        name: 'query',
        component: () => import('@/views/query'),
        meta: {
            title: 'Xbk-query'
        }
    },
]

const router = new VueRouter({
    routes
})

export default router
